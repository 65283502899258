<template>
  <div v-if="finalImageUrl" class="advertisement">
    <a href="https://issuu.com/trinityvoice/docs/february_2025" target="_blank">
      <img :src="finalImageUrl" alt="Advertisement" />
    </a>
  </div>
</template>

<script>
import defaultAdImage from '../assets/images/voice.png';

export default {
  name: 'AdvertisementComponent',
  props: {
    imageUrl: {
      type: String,
      default: defaultAdImage
    }
  },
  computed: {
    finalImageUrl() {
      return this.imageUrl || defaultAdImage;
    }
  }
};
</script>

<style scoped>
* {
  justify-content: center;
}

.advertisement img {
  max-width: 80%;
  height: auto;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.advertisement img:hover {
  transform: translateY(-10px); /* Shifts the image up on hover */
}
</style>