<script>
import axios from 'axios';
import WeekDays from '../views/Week.vue';
import TimeString from '../views/Time.vue';
import Background from '../views/Background.vue';
import Periods from '../views/Periods.vue';
import ScheduleToggle from '../views/ScheduleToggle.vue';
import ConstructionBackground from './Construction.vue'; // Import the construction component
import Advertisement from '@/views/Advertisement.vue';

export default {
  name: 'ParentComponent',
  components: {
    WeekDays,
    TimeString,
    Background,
    Periods,
    ScheduleToggle,
    ConstructionBackground,
    Advertisement
  },
  data() {
    return {
      isLoading: true,
      selectedDay: null,
      dayTypes: [],
      highSchoolPeriodData: [],
      middleSchoolPeriodData: [],
      currentSchedule: 'highSchool',
      currentPeriodData: [],
      dayType: null,
      showConstruction: true, // Flag to control which component to show
    };
  },

  async created() {
    const now = new Date();
    const cutoff = new Date('2024-10-01T09:35:00'); // Set cutoff time to October 1st, 10 AM
    this.showConstruction = now < cutoff; // Show construction component if the current date is before the cutoff

    if (!this.showConstruction) {
      await this.fetchScheduleData();
      const savedSchedule = localStorage.getItem('currentSchedule');
      if (savedSchedule) {
        this.currentSchedule = savedSchedule;
        this.updateCurrentPeriodData(); // Update periods data based on saved schedule
      }
      this.isLoading = false;
    }
  },

  methods: {
    async fetchScheduleData() {
      try {
        const response = await axios.get('https://v9scuisqgl.execute-api.us-east-1.amazonaws.com/');
        this.updateScheduleData(response.data);
      } catch (error) {
        console.error('Error fetching schedule data:', error);
      }
    },

    updateScheduleData(data) {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const selectedDay = dayOfWeek === 0 ? 7 : dayOfWeek;

      this.selectedDay = selectedDay;
      this.dayTypes = data.weekSchedule;
      this.highSchoolPeriodData = data.highSchoolSchedule;
      this.middleSchoolPeriodData = data.middleSchoolSchedule;
      this.dayType = this.dayTypes[selectedDay - 1];
      this.adImageUrl = data.adImageUrl;
      this.updateCurrentPeriodData(); // Initialize the current period data
    },

    updateCurrentPeriodData() {
      this.currentPeriodData = this.currentSchedule === 'highSchool' ? this.highSchoolPeriodData : this.middleSchoolPeriodData;
    },

    handleScheduleChange(newSchedule) {
      this.currentSchedule = newSchedule;
      this.currentPeriodData = newSchedule === 'highSchool' ? [...this.highSchoolPeriodData] : [...this.middleSchoolPeriodData];
      localStorage.setItem('currentSchedule', newSchedule);
    }
  }
};
</script>

<template>
  <div id="app">
    <!-- Conditional rendering: Show construction component until October 1st, 10 AM -->
    <ConstructionBackground v-if="showConstruction" />
    
    <!-- Show the regular schedule components after the cutoff -->
    <div v-else>
      <div v-if="isLoading">Loading...</div>
      <div v-else class="section1">
        <Background id="background"></Background> 
        <div id="container">
          <div class="week-days-top-left">
            <WeekDays :selectedDay="selectedDay" :dayTypes="dayTypes" />
          </div>
          <div id="centered-div">
            <TimeString :periods="currentPeriodData" />
          </div>
          <div class="periods">
            <Periods :periods="currentPeriodData" :dayType="dayType" class="periods-placement" />
          </div>
        </div>
      </div>

      <div class="Advertisement-space section2">
       <Advertisement ></Advertisement>
      </div>

      <div class="section3">
        <ScheduleToggle :currentSchedule="currentSchedule" @change:schedule="handleScheduleChange" />
      </div>

      <div id="signature-container">
        <div id="signature">James Hawley</div>
      </div>
    </div>
  </div>
</template>
  
    
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

.assembly-notes {
  margin-bottom: 50px;
}
.section2 {
    padding-top: 60vw;
    padding-bottom: 20px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    padding-left: 15%;
    
}

.section1 {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;
}

.advertisement-space {
  width: 80%;
  text-align: center; /* Center the advertisement image */
  margin: 0 auto;
}

.periods-placement {
    position: absolute;
    top: calc(100vh - 200px); /* Adjust this value as needed to offset from the bottom */
    right: 15vw;
    width: auto; /* Adjust the width as needed, or remove it to use the content's width */
}

#container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#centered-div {
    position: relative;
}

.week-days-top-left {
    position: absolute;

    top: 0px;
    left: 20px;

    font-size: 80px;
    width: 6in;

    margin: 0;
}

#week-days {
    width: 20in;
}

#signature {
    font-family: 'Roboto Mono', monospace;
    position: fixed; /* Fixed positioning to stay in place during scrolling */
    bottom: 5px; /* Distance from the bottom of the viewport */
    left: 5px; /* Distance from the right side of the viewport */ 
    padding: 5px 10px; /* Padding around the text */
    border-radius: 5px; /* Optional: Rounded corners */
    font-size: 30px; /* Text size 20px*/
    z-index: 1000; /* Ensure it stays on top of other content */
}


@media only screen and (max-width: 500px) {

  .section2 {
    padding-top: 65vh;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .week-days-top-left {
    font-size: 40px; /* Smaller font size */
    top: 25px;
    left: 10px;
    width: 3in; /* Adjust width as needed */
  }

  .periods-placement {
    position: absolute;
    top: calc(100vh - 200px); /* Adjust this value as needed to offset from the bottom */
    right: 15vw;
    width: auto; /* Adjust the width as needed, or remove it to use the content's width */
  }

  .advertisement-space {
    width: 100%; /* Full width on small screens */
  }

  .advertisement-space img {
    max-width: 100%;
    height: auto;
  }

  /* Adjust container widths as needed */
  .section1, .section2 {
    width: 100%; /* Full width */
  }

  #signature-container {
        display: flex; /* Enables flexbox */
        justify-content: center; /* Centers children horizontally */
        bottom: 20px; /* Distance from the bottom */
        left: 0; /* Aligns the container to the left edge */
        right: 0; /* Aligns the container to the right edge */
        width: 100%; /* Ensures the container spans the full width */
    }

  #signature {
    position: static;
    }

  /* Add any other necessary style adjustments here */
}



</style>
  