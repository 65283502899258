<template>
  <div class="week-container" :style="{ 'justify-content': justifyContent }">
    <div 
      class="day" 
      v-for="(day, index) in dayTypes" 
      :key="index"
      :class="{ 
        'underline': selectedDay === index + 1,
        'wednesday-special': index === 2 // Assuming index 2 is Wednesday
      }"
    >
      {{ day }}
    </div>
  </div>
</template>


<script>
export default {
  name: 'WeekDays',
  props: {
    selectedDay: {
      type: Number,
      default: 0
    },
    dayTypes: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
  },
  computed: {
    justifyContent() {
        return this.dayTypes.length === 5 ? 'space-between' : 'space-evenly';
    }
  }
};
</script>

<style scoped>
@import '@/assets/css/fonts.css';

.week-container {
  display: flex;
  width: 100%;
  font-family: "Karla", sans-serif;
}

.day {
  cursor: pointer;
  color: #E55451; /* Default text color */
}

.culture-fest {
  color: #FFA500; /* Special color for Culture Fest */
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #FFA500;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}
</style>
